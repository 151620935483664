<template>
  <div class="vhc-container" style="position: relative" :class="{ 'has-mask': !isOpen }">
    <!-- 标题栏 -->
    <pageTitle pageTitle="观看有礼">
      <div slot="content">
        1.直播中达到指定观看时长后，将有机会获得礼品；观看回放不支持
        <br />
        2.观看时长定义：同一个观众在直播间的累计时长，观众退出直播间重新进入或刷新直播间，观看时长将累计计算。需要注意：更换观众身份将重新计算观看时长
        <br />
        3.同一时间，只能存在一个抽奖，抽奖结束可再次发起抽奖
        <br />
        4.领奖数据更新频率：10秒，可在活动结束后查看完整数据
      </div>
      <p class="switch__box">
        <vh-switch
          style="display: block; padding-left: 8px"
          v-model="isOpen"
          @change="changeOpen"
          active-color="#FB3A32"
          inactive-color="#CECECE"
          :active-text="`${
            isOpen ? '已开启' : '开启后'
          }，直播中方可使用「观看有礼」功能，达到提升观众停留在直播间的时长`"
        ></vh-switch>
      </p>
    </pageTitle>

    <TaskList></TaskList>

    <begin-play :webinarId="$route.params.str"></begin-play>
  </div>
</template>
<script>
  import PageTitle from '@/components/PageTitle';
  import TaskList from './TaskList.vue';
  // import RewardList from './RewardList.vue';
  import beginPlay from '@/components/beginBtn';
  import to from 'await-to-js';

  export default {
    name: 'InteractReward',
    components: {
      PageTitle,
      TaskList,
      // RewardList,
      beginPlay
    },
    data() {
      return {
        isOpen: false, //是否开启观看有礼
        webinarId: '' //活动id
      };
    },
    created() {
      this.webinarId = this.$route.params.str;
      this.initConfig();
    },
    methods: {
      // 获取观看有礼的开关配置
      async initConfig() {
        const params = {
          webinar_id: this.webinarId
        };
        const [err, res] = await to(this.$fetch('getWatchGiftConfig', params));
        if (err || res?.code != 200) {
          this.$message.error(err?.msg || res?.msg || '操作失败');
          return;
        }
        this.isOpen = res.data.watch_gift_status == 1;
      },
      //观看有礼开启状态切换
      async changeOpen() {
        const params = {
          webinar_id: this.webinarId,
          watch_gift_status: Number(this.isOpen)
        };
        const [err, res] = await to(this.$fetch('switchWatchGift', params));
        if (err || res?.code != 200) {
          this.isOpen = !this.isOpen;
          this.$message.error(err?.msg || res?.msg || '操作失败');
          return;
        }
      },
      handleTabClick() {
        this.$router.replace({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            tab: this.activeTab
          }
        });
      }
    }
  };
</script>
<style lang="less">
  .interactive-prize-tabs {
    background-color: #fff;

    .vh-tabs__header {
      margin: 0;
      // padding: 0;
      .vh-tabs__nav-wrap {
        padding-right: 20px;
        &::after {
          background-color: #e6e6e6;
        }
      }
    }
    .vh-tabs__item {
      height: 48px;
      line-height: 48px;
      font-size: 16px;
    }
    .vh-tabs__content {
      // padding: 0;
    }
  }
</style>
<style lang="less" scoped>
  .vhc-container.has-mask {
    &::before {
      content: '';
      position: absolute;
      top: 45px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 5;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
</style>
